import React, {useMemo} from 'react';
import {Dropdown} from 'react-bootstrap';
import classNames from 'classnames';
import {
  formatDateShort,
  formatPrice,
  openExternal,
  formatPriceNoCurrencySymbol,
} from 'shared/utils.js';
import translateSubStatus from 'shared/ui/translateSubStatus.js';
import text_styles from 'shared/styles/text_styles.module.scss';
import Icon from 'shared/components/Icon.js';
import {
  DEPOSIT_SUBSTATUS,
  DEPOSIT_TYPES,
  MANAGER_PATHS,
  TAX_SUMMARY_YEAR,
  TYPES,
} from 'shared/constants.js';
import {RPC} from 'shared/api.js';
import {useHistory} from 'react-router-dom';
import {useNotify} from 'shared/NotifyProvider.js';
import {useSelector} from 'react-redux';

import {
  isClaimAllowed,
  isDeleteAllowed,
  isReleaseAllowed,
} from '../../lib/utils.js';
import {
  alert,
  showDialog,
  deleteDepositGuarantee,
  handleError,
} from '../../effects.js';
import {ReactComponent as MoreVerticalSvg} from '../../assets/more_vertical.svg';
import {Table} from '../../components/Table/Table.js';
import {useTableContext} from '../../components/Table/context.js';

import styles from './DepositsTable.module.scss';
import {ReactComponent as IconCashSvg} from './icon_cash.svg';
import {ReactComponent as IconGuaranteeSvg} from './icon_guarantee.svg';

const PLACEHOLDER_TEXT_NONE_CREATED = (
  <>
    Sie haben noch keine Mieter angelegt.
    <br />
    Sie können Ihren ersten Mieter per Klick auf „Mieter hinzufügen“ anlegen.
  </>
);

const PLACEHOLDER_TEXT_NO_MATCHES = (
  <>
    Leider konnten wir keine Ergebnisse finden.
    <br />
    Bitte überprüfen Sie Ihre Suchanfrage und Filtereinstellungen.
  </>
);

export default function DepositsTable({search_query, reset_table_trigger}) {
  const {refresh} = useSelector((state) => state.deposit_guarantees);
  const actions = useDepositsTableActions();
  return (
    <Table
      data_fetching_rpc_method_name="getDeposits"
      columns={columns}
      actions={actions}
      search_query={search_query}
      default_action="onOpen"
      refresh_trigger={refresh}
      reset_trigger={reset_table_trigger}
      no_match_label={PLACEHOLDER_TEXT_NO_MATCHES}
      empty_table_label={PLACEHOLDER_TEXT_NONE_CREATED}
    />
  );
}

/**
 * @type {Array<import('../../components/Table/context.js').ColumnDef>}
 */
export const columns = [
  {
    name: 'rental_unit',
    Header: 'Mieteinheit',
    Cell: RentalUnitCell,
    sort: 'tenant_last_name',
    width: 'minmax(200px, 4fr)',
  },
  {
    name: 'address',
    Header: 'Adresse',
    Cell: AddressCell,
    sort: 'region',
    width: 'minmax(200px, 2fr)',
  },
  {
    name: 'deposit_status',
    Header: 'Kaution',
    Cell: DepositStatusCell,
    width: 'max-content',
  },
  {
    name: 'deposit_details',
    Header: null,
    Cell: DepositDetailsCell,
    width: 'minmax(160px, 2fr)',
  },
  {
    name: 'deposit_type',
    Header: 'Art',
    Cell: DepositTypeCell,
    width: 'max-content',
  },
  {
    name: 'created_at',
    Header: 'Erstellt',
    width: 'max-content',
    accessor: ({created_at}) => formatDateShort(created_at),
    sort: 'created_at',
  },
  {
    name: 'actions',
    Header: () => null,
    Cell: MenuCell,
    width: 'max-content',
    action: null,
  },
];

export function useDepositsTableActions() {
  const {notify} = useNotify();
  const history = useHistory();
  const actions = useMemo(
    () => ({
      onOpen: (deposit) => {
        deposit.deposit_type === DEPOSIT_TYPES.deposit_guarantee
          ? history.push(
              `${MANAGER_PATHS.DepositGuaranteeDialog}/${deposit.id}`,
            )
          : history.push(`${MANAGER_PATHS.CashDepositDialog}/${deposit.id}`);
      },
      onDelete: (deposit) =>
        deleteDepositGuarantee({deposit_guarantee: deposit, notify}),
      onRelease: (deposit) => onReleaseDepositGuarantee(deposit),
      onClaim: (deposit) => onRequestClaim(deposit),
      openTaxSummary: (deposit) => openTaxSummary(deposit),
      doPayout: (deposit) => {
        history.push(
          `${MANAGER_PATHS.InitiateCreditTransferDialog}/${deposit.id}`,
        );
      },
      showTransactions: (deposit) => {
        history.push(
          `${MANAGER_PATHS.CashDepositTransactionsDialog}/${deposit.id}`,
        );
      },
    }),
    [history, notify],
  );

  return actions;
}

function RentalUnitCell({value: row}) {
  const {
    tenant_first_name,
    tenant_last_name,
    type,
    number_of_tenants,
    given_reference,
    tenant_company_name,
  } = row;

  function renderTenantName() {
    if (tenant_company_name) {
      return (
        <span className={styles.tenant_display_name}>
          {tenant_company_name}
        </span>
      );
    }

    return (
      <>
        <span className={styles.tenant_display_name}>
          {tenant_last_name},&nbsp;{tenant_first_name}
        </span>
        {type === TYPES.individual && number_of_tenants > 1 && (
          <span>&nbsp;+1</span>
        )}
      </>
    );
  }

  function renderReference() {
    return (
      <span
        className={classNames(
          text_styles.body1_italic,
          styles.given_reference,
        )}>
        {given_reference || '–'}
      </span>
    );
  }

  return (
    <div className={styles.rental_unit}>
      <span
        className={classNames(
          text_styles.body1_bold_left,
          styles.tenants_names,
        )}>
        {renderTenantName()}
      </span>
      <br />
      {renderReference()}
    </div>
  );
}

function AddressCell({value: row}) {
  const {street_name, street_number, postal_code, region} = row;
  return street_name ? (
    <div className={styles.address}>
      <span>
        {street_name} {street_number},
      </span>
      <br />
      <span>
        {postal_code} {region}
      </span>
    </div>
  ) : (
    '–'
  );
}

function DepositStatusCell({value: row}) {
  const {sub_status} = row;
  return (
    <div className={styles.status}>
      <div
        className={classNames(
          styles.status_circle,
          styles[getStatusColor(sub_status)],
        )}
      />
    </div>
  );
}

function DepositDetailsCell({value: row}) {
  const {
    status,
    sub_status,
    claims,
    deposit_type,
    deposit_guarantee_start_date,
    account_balance_cents,
    deposit_amount_cents,
  } = row;

  if (deposit_type === DEPOSIT_TYPES.deposit_guarantee) {
    return (
      <div className={styles.details}>
        <div className={styles.details_text}>
          <p>{status}</p>
          <p
            className={classNames(text_styles.caption_left, styles.sub_status)}>
            {translateSubStatus({
              sub_status,
              claims,
              deposit_guarantee_start_date,
              account_balance_cents,
            })}
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.details}>
      <span>
        <span className={text_styles.body1_bold_left}>
          {formatPriceNoCurrencySymbol(account_balance_cents)}
        </span>
        &nbsp;/&nbsp;
        {formatPrice(deposit_amount_cents)}
      </span>
    </div>
  );
}

function DepositTypeCell({value: row}) {
  if (row.deposit_type === DEPOSIT_TYPES.cash_deposit) {
    return <IconCashSvg />;
  }
  if (row.deposit_type === DEPOSIT_TYPES.deposit_guarantee) {
    return <IconGuaranteeSvg />;
  }
}

function MenuCell({value: row}) {
  return (
    <Dropdown className={styles.dropdown}>
      <Dropdown.Toggle as="button">
        <Icon>
          <MoreVerticalSvg />
        </Icon>
      </Dropdown.Toggle>

      <Dropdown.Menu align="end">
        {row.deposit_type === DEPOSIT_TYPES.cash_deposit ? (
          <CashDepositMenuItems deposit={row} />
        ) : (
          <DepositGuaranteeMenuItems deposit={row} />
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function DepositGuaranteeMenuItems({deposit}) {
  const {actions} = useTableContext();
  const {onDelete, onRelease, onClaim} = actions;

  return (
    <>
      <Dropdown.Item
        as="button"
        className={!isDeleteAllowed(deposit) && styles.disabled}
        onClick={() => onDelete(deposit)}>
        Mieter entfernen
      </Dropdown.Item>
      {!deposit.released_at && (
        <>
          <Dropdown.Item
            as="button"
            className={!isReleaseAllowed(deposit) && styles.disabled}
            onClick={() => onRelease(deposit)}>
            Kaution freigeben (Checkliste)
          </Dropdown.Item>
          <Dropdown.Item
            as="button"
            className={!isClaimAllowed(deposit) && styles.disabled}
            onClick={() => onClaim(deposit)}>
            Kaution einfordern
          </Dropdown.Item>
        </>
      )}
    </>
  );
}

function CashDepositMenuItems({deposit}) {
  const {actions} = useTableContext();
  const {doPayout, openTaxSummary, showTransactions} = actions;
  return (
    <>
      <Dropdown.Item as="button" onClick={() => doPayout(deposit)}>
        Auszahlung
      </Dropdown.Item>
      <Dropdown.Item as="button" onClick={() => openTaxSummary(deposit)}>
        Einzelaufstellung
      </Dropdown.Item>
      <Dropdown.Item as="button" onClick={() => showTransactions(deposit)}>
        Transaktionen
      </Dropdown.Item>
    </>
  );
}

function getStatusColor(sub_status) {
  switch (sub_status) {
    case DEPOSIT_SUBSTATUS.released:
    case DEPOSIT_SUBSTATUS.all_claims_paid_out:
    case DEPOSIT_SUBSTATUS.account_balance_empty: {
      return 'status_silver';
    }
    case DEPOSIT_SUBSTATUS.rejected_by_tenant:
    case DEPOSIT_SUBSTATUS.rejected_by_getmomo: {
      return 'status_red';
    }
    case DEPOSIT_SUBSTATUS.video_identification_required:
    case DEPOSIT_SUBSTATUS.invitation_sent:
    case DEPOSIT_SUBSTATUS.cash_deposit_paid_in_part: {
      return 'status_orange';
    }
    case DEPOSIT_SUBSTATUS.cash_deposit:
    case DEPOSIT_SUBSTATUS.some_claims_paid_out:
    case DEPOSIT_SUBSTATUS.claim_requested:
    case DEPOSIT_SUBSTATUS.active:
    case DEPOSIT_SUBSTATUS.active_in_future:
    case DEPOSIT_SUBSTATUS.cash_deposit_paid_in_full: {
      return 'status_green';
    }
  }
}

function onRequestClaim(deposit) {
  if (!isClaimAllowed(deposit)) {
    return alert({
      title:
        'Sie können die Kaution erst einfordern wenn der Vertrag unterschrieben wurde und der Status „Aktiv“ angezeigt wird.',
    });
  }

  showDialog('claim', {
    deposit,
  });
}

function onReleaseDepositGuarantee(deposit) {
  if (!isReleaseAllowed(deposit)) {
    return alert({
      title:
        'Sie können die Kaution erst freigeben wenn der Vertrag unterschrieben wurde und der Status „Aktiv“ angezeigt wird.',
      text: 'Wenn Sie den noch nicht aktivierten Vertrag stornieren möchten, können Sie den Mieter entfernen.',
    });
  }

  showDialog('release', {
    deposit,
  });
}

async function openTaxSummary(deposit) {
  let tax_summary_url;

  try {
    tax_summary_url = await RPC('getTaxSummaryURL', {
      deposit_id: deposit.id,
      year: TAX_SUMMARY_YEAR,
    });
  } catch (err) {
    return handleError(err);
  }
  openExternal(tax_summary_url);
}
